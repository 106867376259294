import * as React from "react";
import LazyLoad from "react-lazyload";
import {
  MobileView,
  // MobileOnlyView,
  BrowserView,
  // TabletView,
} from "react-device-detect";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import Cta from "../components/cta";
import Companies from "../components/companies";
import CompaniesMobile from "../components/companiesmobile";
import CDiagram from "../components/cdiagram";
import CDiagramMobile from "../components/cdiagrammobile";
import { StaticImage } from "gatsby-plugin-image";
import insight from "../contents/slider-insight.json";
import InsightSlider from "../components/insightslider";
import IndustryTab from "../components/industrytab";
import IndustryAccordion from "../components/industryaccordion";
import IVOSolutions from "../components/ivosolutions";
import TestimonialsTabs from "../components/testimonialstabs";
import TestimonialsAccordion from "../components/testimonialsaccordions";
import * as Styles from "../styles/index.module.css";
import "../styles/signal.css";
import "../styles/herofeatures.css";

const IndexPage = () => {
  // const breakpoints = useBreakpoint();
  return (
    <Layout pageTitle="Home Page">
      {/* MetaTag */}
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>
          KATSANA Vehicle Tracking System & Fleet Management Solutions Provider
        </title>
        <meta
          name="description"
          content="KATSANA is the leader in fleet management solutions, helping 1,600+ companies across South East Asia transform their vehicle operations from vehicle location tracking, vehicle & driver management to fleet electrification."
        />
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href="https://www.katsana.com/" />

        {/* Open Graph */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.katsana.com" />
        <meta
          property="og:title"
          content="Leading Vehicle Tracking System & Fleet Management Solutions Provider"
        />
        <meta
          property="og:description"
          content="KATSANA is the leader in fleet management solutions, helping 1,600+ companies across South East Asia transform their vehicle operations from vehicle location tracking, vehicle & driver management to fleet electrification."
        />
        <meta
          property="og:image"
          content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
        />
        <meta
          property="og:image:alt"
          content="Leading Vehicle Tracking System & Fleet Management Solutions Provider"
        />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="640" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@katsanagps" />
        <meta name="twitter:creator" content="@katsanagps" />

        <meta
          name="twitter:image"
          content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
        />

        <script type="application/ld+json">
            {`
              [
                {
                  "@context": "https://schema.org",
                  "@graph": [
                    {
                      "@type": "Organization",
                      "name": "KATSANA",
                      "url": "https://www.katsana.com",
                      "logo": "https://www.katsana.com/wp-content/uploads/2020/08/katsana-logo.png",
                      "description": "KATSANA offers advanced fleet management, GPS tracking, and telematics solutions to businesses across Southeast Asia region.",
                      "foundingDate": "2013",
                      "founders": [
                        {
                          "@type": "Person",
                          "name": "Syed Ahmad Fuqaha"
                        },
                		{
                          "@type": "Person",
                          "name": "Mohd Irwan Ibrahim"
                        },
                		{
                          "@type": "Person",
                          "name": "Ahmad Syafiq Noh"
                        }
                      ],
                      "contactPoint": [
                        {
                          "@type": "ContactPoint",
                          "telephone": "+603-7733-4474",
                          "contactType": "Customer Service",
                          "areaServed": "MY",
                          "availableLanguage": ["English", "Malay"]
                        },
                        {
                          "@type": "ContactPoint",
                          "telephone": "+6019-396-0127",
                          "contactType": "Sales",
                          "areaServed": "MY",
                          "availableLanguage": ["English", "Malay"]
                        },
                        {
                          "@type": "ContactPoint",
                          "email": "sales@katsana.com",
                          "contactType": "Sales",
                          "areaServed": "MY",
                          "availableLanguage": ["English", "Malay"]
                        },
                        {
                          "@type": "ContactPoint",
                          "email": "support@katsana.com",
                          "contactType": "Technical Support",
                          "areaServed": "MY",
                          "availableLanguage": ["English", "Malay"]
                        }
                      ],
                      "address": {
                        "@type": "PostalAddress",
                        "streetAddress": "First Floor, Lot 2805, Jalan Damansara",
                        "addressLocality": "Kuala Lumpur",
                        "postalCode": "60000",
                        "addressCountry": "MY"
                      },
                      "sameAs": [
                        "https://www.facebook.com/KatsanaGPS",
                        "https://twitter.com/katsanaGPS",
                        "https://www.linkedin.com/company/katsana"
                      ]
                    },
                    {
                      "@type": "WebPage",
                      "url": "https://www.katsana.com",
                      "name": "KATSANA - Fleet Management & GPS Tracking Solutions",
                      "description": "KATSANA provides cutting-edge fleet management and GPS tracking solutions for large fleets to optimize vehicle operations, reduce fleet operation costs and improve safety.",
                      "isPartOf": {
                        "@id": "https://www.katsana.com"
                      },
                      "breadcrumb": {
                        "@type": "BreadcrumbList",
                        "itemListElement": [
                          {
                            "@type": "ListItem",
                            "position": 1,
                            "name": "Home",
                            "item": "https://www.katsana.com"
                          }
                        ]
                      }
                    },
                    {
                      "@type": "Product",
                      "name": "KATSANA Fleet Management System (FMS)",
                      "image": "https://www.katsana.com/wp-content/uploads/2025/02/fleet-management-system.jpg",
                      "description": "Advanced fleet management solution with real-time tracking, driver behavior analysis, and maintenance scheduling.",
                      "brand": {
                        "@type": "Brand",
                        "name": "KATSANA"
                      },
                      "sku": "KATSANA-FMS",
                      "offers": {
                        "@type": "Offer",
                        "url": "https://www.katsana.com/fleet-management-malaysia/",
                        "priceCurrency": "MYR",
                        "price": "799.00",
                        "availability": "https://schema.org/InStock"
                      }
                    },
                	{
                      "@type": "Product",
                      "name": "KATSANA EV Fleet Management System (EVX)",
                      "image": "https://www.katsana.com/wp-content/uploads/2025/02/ev-fleet-management.jpg",
                      "description": "Advanced EV Fleet Management System (EVX) to manage electric vehicles on an integrated, unified telematics platform.",
                      "brand": {
                        "@type": "Brand",
                        "name": "KATSANA"
                      },
                      "sku": "KATSANA-EVX",
                      "offers": {
                        "@type": "Offer",
                        "url": "https://www.katsana.com/evx/",
                        "priceCurrency": "MYR",
                        "price": "1099.00",
                        "availability": "https://schema.org/InStock"
                      }
                    },
                    {
                      "@type": "Product",
                      "name": "KATSANA GPS Tracker",
                      "image": "https://www.katsana.com/wp-content/uploads/2025/02/gps-tracker.jpg",
                      "description": "High-precision GPS tracking device for real-time vehicle monitoring and safety.",
                      "brand": {
                        "@type": "Brand",
                        "name": "KATSANA"
                      },
                      "sku": "KATSANA-GPS-002",
                      "offers": {
                        "@type": "Offer",
                        "url": "https://www.katsana.com/gps-tracker-malaysia/",
                        "priceCurrency": "MYR",
                        "price": "399.00",
                        "availability": "https://schema.org/InStock"
                      }
                    },
                    {
                      "@type": "Product",
                      "name": "Truck/Lorry Fuel Monitoring System",
                      "image": "https://www.katsana.com/wp-content/uploads/2025/02/fuel-monitoring-system.jpg",
                      "description": "Monitor fuel spending, fuel level and fuel efficiency for trucks and lorries in real-time.",
                      "brand": {
                        "@type": "Brand",
                        "name": "KATSANA"
                      },
                      "sku": "KATSANA-FUEL-001",
                      "offers": {
                        "@type": "Offer",
                        "url": "https://www.katsana.com/fuel/",
                        "priceCurrency": "MYR",
                        "price": "899.00",
                        "availability": "https://schema.org/InStock"
                      }
                    },
                	    {
                      "@type": "Product",
                      "name": "AI Mobile Digital Video Recorder",
                      "image": "https://www.katsana.com/wp-content/uploads/2025/02/adas-mdvr-dashcam.jpg",
                      "description": "Real-time MDVR, ADAS, fleet dashcam and onboard cameras for fleet operations.",
                      "brand": {
                        "@type": "Brand",
                        "name": "KATSANA"
                      },
                      "sku": "KATSANA-MDVR-001",
                      "offers": {
                        "@type": "Offer",
                        "url": "https://www.katsana.com/mdvr/",
                        "priceCurrency": "MYR",
                        "price": "899.00",
                        "availability": "https://schema.org/InStock"
                      }
                    },
                    {
                      "@type": "Service",
                      "name": "Fleet Tracking & Telematics",
                      "provider": {
                        "@type": "Organization",
                        "name": "KATSANA"
                      },
                      "areaServed": "MY, ID, TH, AU, BN, SG",
                      "serviceType": "GPS Tracking and Fleet Management",
                      "url": "https://www.katsana.com/fleet-management-malaysia/"
                    }
                  ]
                }
              ]            
            `}
          </script>

      </Helmet>
      {/* Hero Area */}
      <div
        className={
          Styles.tilt +
          " bg-gradient-to-br from-[hsla(211,55%,22%,1)] via-[hsla(211,45%,16%,1)] to-[hsla(211,45%,16%,1)] pt-20 md:pt-24 xl:pt-28 md:pb-16 after:h-[2%] after:bg-gradient-to-b after:from-[hsla(210,75%,22%,1)] after:to-[hsla(210,75%,22%,1)] "
        }
      >
        {/* Set Max Width */}
        <div className="relative mx-auto max-w-primary">
          <div className="flex justify-center">
            
            {/* Hero Text & CTA */}
            <div className="flex flex-col px-8 py-4 lg:w-full z-10 items-center justify-center">
              <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white text-center leading-10 md:leading-10 lg:leading-none xl:max-w-[960px]">
                <span className="text-4xl md:text-5xl lg:text-6xl">The leading Vehicle Tracking System & Fleet Management Solutions Provider</span>
              </h1>
              <p className="mt-4 text-sm leading-6 md:mt-7 md:leading-8 md:text-xl text-bluegray md:max-w-lg text-center xl:max-w-[550px]">
                KATSANA is the leader in fleet management solutions,
                helping 1,600+ companies across South East Asia
                transform their vehicle operations from
              </p> 
              <div className="mt-0 lg:mt-0 text-sm leading-6 md:mt-0 md:leading-8 md:text-xl text-bluegray text-center">
                <a href="/gps-tracker-malaysia/" className="text-primary transition-all">vehicle location tracking</a>, {' '}
                <a href="/fleet-management-malaysia/" className="text-primary transition-all">vehicle & driver management</a>
                {' '} to
                <a href="/ev/" className="inline-flex items-center text-primary transition-all">
                  <span className="bolt-pink text-primary stroke-primary bg-left-bottom">
                  fleet electrification
                  </span>
                </a>.
              </div>
            </div>

          </div>

          <div className="max-w-6xl p-8 pb-4 mx-auto flex justify-center">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 sm:gap-6 w-full z-10">
              
              <div class="group rounded-xl cursor-pointer relative shadow-2xl ring-4 ring-transparent hover:ring-primary hover:ring-4 border-solid overflow-hidden hover:transition-all hover:duration-1000">
                {/*Background image*/}
                <div className="transition-all ease-in-out duration-1000 group-hover:ease-in-out group-hover:scale-125 fms h-36 md:h-56"></div>
                <div className="flex flex-col bg-gradient-hero">
                  <div className="flex flex-col px-4 py-3 md:py-5 text-white group-hover:text-primary hover:transition-all duration-700 absolute bottom-0 z-10">
                    <span className="text-white text-sm sm:text-xl font-bold">Fleet Management Solutions, FMS</span>
                    <div className="flex items-end text-primary mt-1 sm:mt-2">
                      <span className="text-sm font-normal">Learn more</span>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4 ml-1">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                      </svg>
                    </div>
                  </div>
                </div>
                <a href="/fleet-management-malaysia/" class="stretched-link"></a>
              </div>
             
              <div className="group rounded-xl cursor-pointer relative shadow-2xl ring-4 ring-transparent hover:ring-primary hover:ring-4 border-solid overflow-hidden hover:transition-all hover:duration-1000">
                {/*Background image*/}
                <div className="transition-all ease-in-out duration-1000 group-hover:ease-in-out group-hover:scale-125 ivo h-36 md:h-56"></div>
                <div className="flex flex-col bg-gradient-hero">
                  <div className="flex flex-col px-4 py-3 md:py-5 text-white group-hover:text-primary hover:transition-all duration-700 absolute bottom-0 z-10">
                    <span className="text-white text-sm sm:text-xl font-bold">Integrated Vehicle Operations, IVO</span>
                    <div className="flex items-end text-primary mt-1 sm:mt-2">
                      <span className="text-sm font-normal">Learn more</span>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4 ml-1">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                      </svg>
                    </div>
                  </div>
                </div>
                <a href="https://apps.katsana.com/get-quotation/" class="stretched-link" target="_blank"></a>
              </div>

              <div className="group rounded-xl cursor-pointer relative shadow-2xl ring-4 ring-transparent hover:ring-primary hover:ring-4 border-solid overflow-hidden hover:transition-all hover:duration-1000">
                {/*Background image*/}
                <div className="transition-all ease-in-out duration-1000 group-hover:ease-in-out group-hover:scale-125 evx h-36 md:h-56"></div>
                <div className="flex flex-col bg-gradient-hero">
                  <div className="flex flex-col px-4 py-3 md:py-5 text-white group-hover:text-primary hover:transition-all duration-700 absolute bottom-0 z-10">
                    <span className="text-white text-sm sm:text-xl font-bold flex items-start">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="text-primary size-5 sm:size-8 mr-1">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                      </svg>
                      Fleet Electrification Solutions, EVX
                    </span>
                    <div className="flex items-end text-primary mt-1 sm:mt-2">
                      <span className="text-sm font-normal">Learn more</span>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4 ml-1">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                      </svg>
                    </div>
                  </div>
                </div>
                <a href="/ev/" class="stretched-link"></a>
              </div>

            </div>
          </div>

          {/* Companies / Client */}
          <LazyLoad>
            <div className="pt-2 md:pt-2">
              <BrowserView>
                <Companies />
              </BrowserView>

              <MobileView>
                <CompaniesMobile />
              </MobileView>
            </div>
          </LazyLoad>

          {/* IVO Solutions */}
          <div className="max-w-5xl py-1 md:py-0 lg:pt-5 lg:pb-8 mx-auto">
            <IVOSolutions />
          </div>

          {/* Testimonials */}
          <div className="max-w-5xl px-8 pt-8 pb-24 md:pt-8 md:pb-8 lg:pt-16 lg:pb-16 mx-auto">

            <MobileView>
              <LazyLoad>
                <TestimonialsAccordion />
              </LazyLoad>
            </MobileView>

            <BrowserView>
              <LazyLoad>
                <TestimonialsTabs />
              </LazyLoad>
            </BrowserView>

          </div>

        </div>
      </div>

      {/* On The Cloud */}
      <div
        className={
          Styles.tilt +
          " bg-gradient-to-b from-[hsla(210,75%,22%,1)] to-[hsla(219,78%,41%,1)] mt-[-1px] after:h-[2%] after:bg-[hsla(202,28%,77%,1)] "
        }
      >
        <div className={Styles.cloudbg + " p-8 pb-16 md:pb-32 md:pt-12"}>
          <div className="max-w-6xl mx-auto">
            <h2 className="text-2xl font-semibold text-white md:text-center md:text-4xl">
              Complete Visibility of Fleet Operations in Real-Time
            </h2>
            <p className="my-4 text-sm leading-6 md:text-center md:leading-8 md:text-lg text-bluegray md:max-w-2xl md:mx-auto">
              KATSANA Fleet Management Solutions make fleet & vehicle operations more efficient and cost-effective by making data & operational insights available in real-time
              for you to make impactful and data-informed decisions.
            </p>
            <MobileView>
              <LazyLoad>
                <CDiagramMobile />
              </LazyLoad>
            </MobileView>
            <BrowserView>
              <LazyLoad>
                <CDiagram />
              </LazyLoad>
            </BrowserView>
          </div>
        </div>
      </div>
      {/* Industry-Driven Solutions */}
      <div
        className={
          " bg-gradient-to-b from-[hsla(202,28%,77%,1)] to-[hsla(198,26%,93%,1)] mt-[-1px] pb-0 "
        }
      >
        <div className="max-w-6xl mx-auto md:pl-8 xl:pl-0 md:py-16">
          <h2 className="p-8 mb-4 text-2xl font-semibold text-blue-800 md:font-bold md:text-4xl md:mb-8 md:pl-0 ">
            Industry-driven solutions for modern fleet operations
          </h2>

          <div className="pb-8 pl-8 md:pl-0">
            <MobileView>
              <LazyLoad>
                <IndustryAccordion />
              </LazyLoad>
            </MobileView>

            <BrowserView>
              <LazyLoad>
                <IndustryTab />
              </LazyLoad>
            </BrowserView>

            <div className="flex flex-col justify-between pr-8 mt-8 md:mt-16 md:flex-row md:items-center md:pr-0 ">
              <div className="text-sm leading-6 text-blue-900 md:text-base md:w-2/3">
                <p>
                  <span className="font-medium">
                    KATSANA Fleet Management and Vehicle Operation solutions{" "}
                  </span>
                  are developed to meet the exact needs of the industry. Each
                  solution is the result of deep customer understanding through
                  extensive research to identify problem statement and
                  operational needs prior to solution roll-out.
                </p>
                <p className="mt-4">
                  We take pride in our industry-focused solutions thanks to your
                  input, our customers.
                </p>
              </div>
              <div className="mt-8 mr-8 md:mt-0">
                <a
                  href="https://apps.katsana.com/get-quotation/"
                  className="inline-flex px-5 py-3 text-sm font-semibold text-white rounded-full md:px-6 md:text-base bg-primary"
                >
                  Request Consultation
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Industry Insights and Updates */}
      <div className="relative p-8 pr-0 overflow-hidden md:p-0 md:pt-32 md:pb-48">
        <StaticImage
          alt="katsana"
          src="../../static/images/photos/industrialiot.jpg"
          objectFit="cover"
          className="!absolute inset-0"
        />

        <div className="absolute top-0 left-0 w-full">
          <div
            className={
              Styles.tiltr +
              " after:bg-[hsla(198,26%,93%,1)] z-10 m-[-1px] after:h-[4vh] md:after:h-[10vh] "
            }
          ></div>
        </div>

        <div className="max-w-6xl py-8 mx-auto md:pl-8">
          <h2 className="relative z-10 mt-8 mb-8 text-xl font-semibold text-white md:mt-0 md:text-4xl">
            Latest Industry Insights
            <br /> and Updates
          </h2>
          <div className="my-16">
            <LazyLoad>
              <InsightSlider jsondata={insight} />
            </LazyLoad>
          </div>
        </div>
      </div>
      <Cta />
    </Layout>
  );
};

export default IndexPage;
